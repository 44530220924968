// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./trans_pride_flag.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.header {\n    padding: 64px 0;\n    margin-bottom: 64px;\n    background: #2575b4 url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") repeat-x left center;\n    background-size: 256px;\n}\n\n.header h1 {\n    position: absolute;\n    padding-left: 32px;\n}\n\n.header h1 a {\n    color: white;\n}\n\n.blahaj {\n    width: 720px;\n    margin: 0 auto;\n}", "",{"version":3,"sources":["webpack://./src/components/header/header.css"],"names":[],"mappings":";AACA;IACI,eAAe;IACf,mBAAmB;IACnB,gFAAsE;IACtE,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,cAAc;AAClB","sourcesContent":["\n.header {\n    padding: 64px 0;\n    margin-bottom: 64px;\n    background: #2575b4 url(\"./trans_pride_flag.svg\") repeat-x left center;\n    background-size: 256px;\n}\n\n.header h1 {\n    position: absolute;\n    padding-left: 32px;\n}\n\n.header h1 a {\n    color: white;\n}\n\n.blahaj {\n    width: 720px;\n    margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
